import React from 'react'

const HomeService = () => {
  return (
    <div>
            <section className="service-section bd-bottom bg-grey padding">
                    <div className="container">
                        <div className="section-heading mb-40 text-center">
                            <span className="sub-heading">What we do?</span>
                            <h2>We Believe That Together <br /> We Can Change More Lives!</h2>
                            <p>Supporting adoption is not just an act of kindness—it's a commitment to building stronger families and brighter futures. </p>
                        </div>{/* /.section-heading */}
                        <div className="service-wrap row">
                            <div className="col-lg-3 col-md-6 sm-padding">
                                <div className="service-box item-1 text-center">
                                    <span />
                                    <div className="service-content">
                                        <i className="cht cht-charity-1" />
                                        <h3>Provide Safe Homes for  Children</h3>
                                        <p>Create Loving Homes for Children in Need.</p>
                                        <a className="read-more" href="#">Read More</a>
                                    </div>
                                    <div className="service-icon">
                                        <i className="cht cht-charity-1" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sm-padding">
                                <div className="service-box item-2 text-center">
                                    <span />
                                    <div className="service-content icon-2">
                                        <i className="cht cht-donation-13" />
                                        <h3>Support Families Ready to Adopt</h3>
                                        <p>Empower Families on Their Adoption Journey.</p>
                                        <a className="read-more" href="#">Read More</a>
                                    </div>
                                    <div className="service-icon icon-2">
                                        <i className="cht cht-donation-13" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sm-padding">
                                <div className="service-box item-3 text-center">
                                    <span />
                                    <div className="service-content icon-3">
                                        <i className="cht cht-charity-6" />
                                        <h3>Education and Care for  Adoptives</h3>
                                        <p>Help Educate and Care for Children in Need.</p>
                                        <a className="read-more" href="#">Read More</a>
                                    </div>
                                    <div className="service-icon icon-3">
                                        <i className="cht cht-charity-6" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sm-padding">
                                <div className="service-box item-4 text-center">
                                    <span />
                                    <div className="service-content icon-4">
                                        <i className="cht cht-charity-12" />
                                        <h3>Health and Well-Being </h3>
                                        <p>Support the Health and Well-Being of Adoptives.</p>
                                        <a className="read-more" href="#">Read More</a>
                                    </div>
                                    <div className="service-icon icon-4">
                                        <i className="cht cht-charity-12" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* ./ service-section */}
    </div>
  )
}

export default HomeService