import React from 'react'
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import HomeAbout from '../Components/HomeAbout'
import Footer from '../Components/Footer'
import WorkingProcess from '../Components/WorkingProcess'
import CounterSection from '../Components/CounterSection'
import ScrollToTop from '../Components/ScrollToTop'

const WhoWeAre = () => {
  return (
    <div>
  <Header/>
 <Banner  Name={"Who We Are"} />
  <HomeAbout/>
  <WorkingProcess/>
  <CounterSection/>
  <Footer/>
  <ScrollToTop/>
</div>
  )
}

export default WhoWeAre