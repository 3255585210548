import React from 'react'
import { useNavigate } from 'react-router-dom'

const VolunteerBlog = () => {
  const navigate=useNavigate()
  return (
    <div>
        <section className="blog-section bg-white padding">
          <div className="container">
            <div className="blog-wrap row">
              <div className="col-lg-12 padding-15">
                <div className="blog-single-wrap">
                  <div className="section-heading mb-40 text-center">
                    <h2> Volunteer at SAA , Ch lakhiram arya jagannath ashram</h2>
                    <h4>Specialized Adoption Agency </h4>
                    <p>Assist our staff in providing care and love to a child. SAA , Ch lakhiram arya jagannath ashram has children as young as one day old up to six years of age. The organization has, on average, 30 children in their care. Volunteers help staff is providing care to the children.</p>
                  </div>{/* /.section-heading */}
                  <div className="blog-single-content">
                    <blockquote>
                      <div className="dots" />
                      <p>“You make a living by what you get. You make a life by what you give.”</p>
                      <span className="quoter">- Winston Churchill</span>
                    </blockquote>
                    <h2 className="mainh2">Benefits of volunteering</h2>
                    <ul className="mainul">
                      <li>Volunteering offers vital help to people in need.</li>
                      <li>Volunteering reduces stress, combat depression, keeps you mentally stimulated, and provides a sense of purpose.</li>
                      <li>Volunteering brings fun and fulfillment to your life.</li>
                    </ul>
                  </div>
                  <div className="col-lg-12 sm-padding">
                    <div className="contact-form">
                      <div className="form-heading">
                        {/* <h3>Get in Touch With Us</h3> */}
                        <p style={{ textAlign: 'center', fontSize: 16, marginTop: 100, marginBottom: 40 }}>If you would like to volunteer with SAA , Ch lakhiram arya jagannath ashram, please complete the form below. A staff member will contact you to answer any questions you may have and to collect additional information (as required).*</p>
                      </div>
                      <form className="form-horizontal text-center">
                        {/* <div className="form-group colum-row row">
                          <div className="col-sm-6">
                            <input type="text" id="contact-firstname" name="contact-firstname" className="form-control custom" placeholder="First Name" required />
                          </div>
                          <div className="col-sm-6">
                            <input type="text" id="contact-lastname" name="contact-lastname" className="form-control custom" placeholder="Last Name" required />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input type="email" id="contact-email" name="contact-email" className="form-control custom" placeholder="Email" required />
                          </div>
                          <div className="col-sm-6">
                            <input type="text" id="contact-phone" name="contact-phone" className="form-control custom" placeholder="Phone Number" required />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <textarea id="contact-message" name="contact-message" cols={30} rows={5} className="form-control custom address" placeholder="Message" required defaultValue={""} />
                          </div>
                        </div> */}
                        <button onClick={()=>navigate("/Contact")} className="default-btn" type="submit">Contact Us<span /></button>
                        <div id="form-messages" className="alert" role="alert" />
                      </form>
                    </div>
                  </div>
                </div>{/*/.blog-single*/}
              </div>{/*/.col-lg-8*/}
            </div>{/*/.blog-wrap*/}
          </div>
        </section>{/*/.blog-section*/}
    </div>
  )
}

export default VolunteerBlog