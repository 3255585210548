import React from 'react'
import { useNavigate } from 'react-router-dom'

const HomeAbout = () => {
    const navigate=useNavigate()
  return (
    <div>
          <section className="about-section bd-bottom padding">
                    <div className="container">
                        <div className="about-wrap row d-flex align-items-center">
                            <div className="col-md-6 sm-padding">
                                <div className="section-heading text-left">
                                    <span className="sub-heading">About us</span>
                                    <h2>Finding a safe, loving and<br /> caring family for each child.</h2>
                                    <p>Ch Lakhiram arya is an adoption center registered under Women &amp; Child Development Department of Haryana. The Specialised Adoption Agency is affiliated with the Central Adoption Resource Authority (CARA). We follow the guidelines set by the Central Govt.</p>
                                </div>{/* /.section-heading */}
                                <a onClick={()=>navigate("/WhatWeDo")} className="default-btn">Learn More<span /></a>
                            </div>
                            <div className="col-md-6 sm-padding">
                                <div className="about-img">
                                    <img src="../assets/img/Childrenmain.png"   />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* ./ about-section */}
    </div>
  )
}

export default HomeAbout