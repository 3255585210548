import React from 'react'
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import CounterSection from '../Components/CounterSection'
import BlogSection from '../Components/BlogSection'
import ScrollToTop from '../Components/ScrollToTop'

const WhatWeDo = () => {
  return (
   <div>
  <div>
    <Header/>
    <Banner  Name={"What We Do"} />
    </div>
  {/* /.page-header */}
  <BlogSection/>
  <CounterSection/>
  <Footer/>
  <div id="scrollup">
    <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
  </div>
  <ScrollToTop/>
</div>
  )
}

export default WhatWeDo