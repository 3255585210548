import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HomeSlider from '../Components/HomeSlider';
import HomeAbout from '../Components/HomeAbout';
import HomeService from '../Components/HomeService';
import HomeTeam from '../Components/HomeTeam';
import ScrollToTop from '../Components/ScrollToTop';

const Home = () => {
    const settings = {
        fade: true, // For smooth transitions
        cssEase: 'linear',
        arrows: false,
    };
    return (
        <div>
            <div>
                <Header />
                <HomeSlider />
                <HomeAbout />
                <HomeService />
                <HomeTeam />
                <Footer />
                <div id="scrollup">
                    <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
                </div>
            </div>
            <ScrollToTop/>
        </div>
    )
}

export default Home