import React from 'react'
const GallerySection = (props) => {
  return (
    <div>
        <div className="gallary-section bg-grey bd-bottom padding">
      {props.error && <p style={{textAlign:"center",color:"red",fontSize:"20px",padding:"5px"}}>Something went Wrong. Try again later</p>}
    <div className="container">
      <div className="row gallary-items">
      {props.loader?<div className='preloader'>
        <div className="loader"></div>
      </div>:props.data?props.data.map(function(source,index)
        {
          return(
            <div key={index} className="col-lg-4 col-md-6 padding-15 single-item education medicine">
            <div className="gallary-item">
            <img height={"280px"} style={{backgroundSize:"cover"}} src={source?source:"../assets/img/Gallerey images/img (1).jpg"} alt="gallary" />
            <div className="gallary-hover">
              <a className="img-popup vbox-item" data-gall="gallery01" href={source?source:"../assets/img/Gallerey images/img (1).jpg"}><i className="ti-plus" /></a>
            </div>
          </div>
        </div>
          )
        }):""}
      </div>
      {
        props.data && <div className="text-center">
        {props.Loading?<p style={{fontSize:"25px",padding:"7px"}}>...Loading</p>:<div style={{display:"flex",justifyContent:"center",cursor:"pointer"}} onClick={props.load}><img style={{height:"42px",width:"40px"}} src="assets/Refresh.png" alt="" /><p style={{fontSize:"25px",padding:"7px"}}>Load More</p></div>}
        </div>
      }
    </div>
  </div>
    </div>
  )
}

export default GallerySection