import React from 'react'
const AdminGalleryImages = (props) => {
  return (
    <div>
    <div style={{padding:"80px 0px"}} className="gallary-section bg-grey bd-bottom padding">
    <div className="container">
        {props.ourerror?<p style={{fontSize:"25px",padding:"5px",color:"red",textAlign:"center"}}>Something Went Wrong. Try again later</p>:
        <div>
          {
          props.len<54?<div className="container">
          <div className="text-center">
          <button className="default-btn" onClick={props.fun}>Upload New Images</button>
          </div>
          </div>:<p style={{fontSize:"15px",textAlign:"center",color:"red",padding:"5px"}}>Storage is full. You can upload only 50 images!</p>
          }
          <div className="row gallary-items">
        {
          props.data?props.data.map((obj,index)=>{    
            return(
              <div key={index} className="col-lg-4 col-md-6 padding-15 single-item education medicine">
          <div className="gallary-item">
            <img height={"300px"} style={{backgroundSize:"cover"}} src={obj.downloadURL?obj.downloadURL:"../assets/img/Gallerey images/img (1).jpg"} alt="gallary" />
            <div className="gallary-hover">
              <a className="img-popup vbox-item" data-gall="gallery01" href={obj.downloadURL?obj.downloadURL:"../assets/img/Gallerey images/img (1).jpg"}><button className='btn btn-success'>Open</button></a>
              <a style={{marginLeft:"5px"}} className="img-popup vbox-item"><button className='btn btn-primary' onClick={()=>props.delete(obj.path)}>Delete</button></a>
            </div>
          </div>
        </div>
            )
          }):""
        }
          </div>
          <div className="text-center">
        {props.makeload?<p style={{fontSize:"25px",padding:"7px"}}>...Loading</p>:<div style={{display:"flex",justifyContent:"center",cursor:"pointer"}} onClick={props.load}><img style={{height:"42px",width:"40px"}} src="assets/Refresh.png" alt="" /><p style={{fontSize:"25px",padding:"7px"}}>Load More</p></div>}
          </div>
        </div>}
    </div>
  </div>
    </div>
  )
}
export default AdminGalleryImages