import React, { useContext, useEffect } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import GallerySection from '../Components/GallerySection'
import Context from '../Pages/Context'
import { useNavigate } from 'react-router-dom'
import ScrollToTop from '../Components/ScrollToTop'
const Gallery = () => {
  const {Fetch,Loading,Error,LoadMore,Loader}=useContext(Context)
  const navigate=useNavigate()
  useEffect(()=>{
      if(!Fetch) return navigate("/")
  },[Fetch])
  return (
  <div>
  <div>
  <Header/>
  <Banner Name={"Outstanding Activities!"} />
  <GallerySection data={Fetch} Loading={Loading} load={LoadMore} loader={Loader} error={Error}/>
  <Footer/>
  <div id="scrollup">
    <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
  </div>
  </div>
  <ScrollToTop/>
  </div>
  )
}

export default Gallery