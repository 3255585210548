import React, { useEffect, useRef, useState } from 'react'
import { database } from '../Firebase'
import jsPDF from 'jspdf'
import ReactHTMLTableToExcel from "react-html-table-to-excel"
const ContactDetails = () => {
    const[obj,setobj]=useState("")
    const[obj1,setobj1]=useState("")
    const table=useRef()
    useEffect(()=>{
        database.child("Contacts").on("value",(snap)=>{
            if(snap.val())
            {
                setobj(snap.val())
                setobj1(snap.val())
            }
            else
            {
                setobj("")
                setobj1("")
            }
        })
    },[])
    const Delete=(key)=>{
        database.child(`Contacts/${key}`).remove(err=>{
            if(err) return alert("Something went Wrong.Try again later")
            alert("Entry Deleted Successfully")
        })
    }
    const search=(event)=>{
      const response = event.target.value.toLowerCase().replace(/[^a-zA-Z0-9\s@.,]/g, ''); // Allow only letters, digits, spaces, @, ., and commas
      if(response==="")
      {
        setobj(obj1);
      }
      else
      {
        let newobj={}
        Object.keys(obj1).filter((key)=>{
          if(obj1[key].Name.toLowerCase().match(response.trim()) || obj1[key].Phone.match(response.trim()) || obj1[key].Email.match(response.trim()))
          {
            newobj[key]=obj1[key]
          }
        })
        if(Object.keys(newobj).length===0)
        {
          setobj("")
        }
        else
        {
          setobj({...newobj})
        }
      }
    }
    const DeleteAll=()=>{
        database.child("Contacts").remove(err=>{
        if(err) return alert("Something went Wrong. Try again later")
        alert("All the Contact details are deleted Successfully")
        })    
    }
    const generatePDF=()=>{
     const report = new jsPDF('portrait','px',[1150,885]);
     report.html(table.current).then(() => {
     report.save('Contacts.pdf');})
    }
  return (
    <div>
      <section className="contact-section bg-grey padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-12 sm-padding">
                <div className="contact-content">
                  <div className="contact-item mb-40">
                    <h3 className='text-center'>Contact Details</h3>
                    <div className="container mb-2">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 mt-1 mb-1">
                            <input type="text" onChange={search} placeholder='Search by Name or Phone or Email' className="form-control" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt-1 mb-1" style={{overflowX:"auto",textWrap:"nowrap"}}>
                            <button className='btn btn-danger' onClick={DeleteAll}>Delete All</button>
                            <button style={{marginLeft:"10px",marginRight:"10px"}} onClick={generatePDF} className='btn btn-primary'>Export to PDF</button>
                            <ReactHTMLTableToExcel className="btn btn-primary" table="table-to-xls" filename="Contacts" sheet="Contacts" buttonText="Export Data to Excel"/>
                        </div>
                    </div>
                    </div>
                    <div ref={table} style={{overflowX:"auto"}} className="container text-center">
                        <table id='table-to-xls' className='table table-bordered table-hover'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                obj?Object.keys(obj).reverse().map((key,index)=>{
                                    return(
                                        <tr key={key}>
                                            <td>{index+1}</td>
                                            <td>{obj[key].Name.slice(0,1).toUpperCase()+obj[key].Name.slice(1)}</td>
                                            <td>{obj[key].Phone}</td>
                                            <td>{obj[key].Email}</td>
                                            <td>{obj[key].Address}</td>
                                            <td>{obj[key].Message}</td>
                                            <td>{obj[key].Date}</td>
                                            <td><button className='btn btn-danger' onClick={()=>Delete(key)}>Delete</button></td>
                                        </tr>
                                    )
                                }):<tr>
                                    <td style={{fontWeight:"700"}} className='text-center' colSpan={8}>No Record Found</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default ContactDetails
