import React, { useEffect } from 'react'
import Header from "../Components/Header"
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import Loginform from '../Components/Loginform'
import ScrollToTop from '../Components/ScrollToTop'
const Login = () => {
  useEffect(()=>{
    localStorage.clear()
  },[])
  return (
    <div>
      <Header/>
      <Banner Name={"Login!"} />
      <Loginform/>
      <Footer/>
      <div id="scrollup">
      <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
      </div>
      <ScrollToTop/>
    </div>
  )
}

export default Login
