import React from 'react'
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import AdoptSect from '../Components/AdoptSect'
import ScrollToTop from '../Components/ScrollToTop'
const Adoption = () => {
  return (
    <div>
      <div>
        <Header/>
        <Banner Name={"Specialised Adoption Agency"} />      
        <AdoptSect/>
        <Footer/>
        <div id="scrollup">
          <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
        </div>
      </div>
    <ScrollToTop/>
    </div>
  )
}

export default Adoption