import React from 'react'

const BlogSection = () => {
  return (
    <div>
        <section className="blog-section bg-white padding">
    <div className="container">
      <div className="blog-wrap row">
        <div className="col-lg-12 padding-15">
          <div className="blog-single-wrap">
            <div className="blog-thumb">
              <img src="../assets/img/MainBanner.jpg" alt="img" />
            </div>
            <div className="blog-single-content">
              <ul className="single-post-meta">
                <li> <a href="#">Front of the Specialized Adoption Agency run by SAA , Ch lakhiram arya jagannath ashram , Rohtak</a></li>
              </ul>
              <p>SAA , Ch lakhiram arya jagannath ashram runs a Specialized Adoption Agency to benefit those couple who do not have a child or want to adopt a child to be a perfect parent to give an opportunity to a child to have a perfect home. Our Adoption Agency is affiliated to the Central Adoption Resource Authority (CARA). We follow the guidelines given by the Central Government.</p>
              <p>It’s unknown exactly how many babies are abandoned each year in India, with some sources reporting up to 11 million children, but it is known that over 90% of them are girls. Babies are abandoned for many reasons: victims of rape or unmarried mothers fearing recriminations, being unable to financially support a child or simply not wanting another girl in the family.</p>
              <p>Many babies are left at our Centre, and our ChildLine team and volunteers also bring us babies found abandoned in public places, sadly sometimes they are found too late. Many of the infants who survive are very fragile and/or premature and require hospitalization and intensive care. Fortunately, most survive, and we start the process of searching for their family and if found counsel them on the next steps. Our adoption process finds loving families for those unwanted and we’re with them every step of the way ensuring a safe and smooth transition to their new home.</p>
              <blockquote>
                <div className="dots" />
                <p>“To give service to a single heart by a single act is better than a thousand heads bowing in prayer.”</p>
                <span className="quoter">- Mahatma Gandhi</span>
              </blockquote>
              <p>Since we opened our doors in 2014, we have successfully rescued and saved the lives of over 136 babies, adopting them under a government approved and accredited process. We have also played a key role consulting on the government’s proposed new adoption guidelines. So far, we have given seventy-two (72) children, thirty (30) boys and forty-two (42) girls in In-Country adoption &amp; two (2) boys and six (6) girls for Inter- Country adoption to eligible CARA registered parents. We have had the privileged to restore Twenty-three (23) children, eleven (11) boys and twelve (12) girls to their respective biological parents.</p>
              <p>We are currently building a new 50-bed adoption Centre with improved neo- and post-natal facilities and space for unmarried mothers to give birth in safety. We are also looking for opportunity to welcome children ages 6 through 18 years of age and looking for opportunity to increase capacity of special need children.</p>
            </div>
          </div>{/*/.blog-single*/}
        </div>{/*/.col-lg-8*/}
      </div>{/*/.blog-wrap*/}
    </div>
  </section>{/*/.blog-section*/}
    </div>
  )
}

export default BlogSection