import React, { useState } from 'react'
import imageCompression from 'browser-image-compression'
import Storage from '../Firebase'
const UploadImage = (props) => {
  const[images,setimages]=useState(null)
  const[base,setbase]=useState(null)
  const[haserror,sethaserror]=useState(null)
  const newimages=async(event)=>{
    const file = event.target.files[0];
    if(file)
    {
      const ext=file.type.split("/")
      if(ext[0]==="image")
      {
        if(ext[1]==="png" || ext[1]==="PNG" || ext[1]==="jpeg" || ext[1]==="jpg")
        {
          const options = {
            maxSizeMB: 2, // Maximum size in MB
            maxWidthOrHeight: 300, // Resize to 300x300
            useWebWorker: true,
          }
          try {
            const compressed = await imageCompression(file, options);
            setimages(compressed)
            setbase(URL.createObjectURL(compressed));      
          } catch (error) {
            sethaserror(error);
          }
        }
        else
        {
          alert("Only png,jpeg,jpg images are supported.")
        }
      }
      else
      {
        alert("Only images are supported")
      }
    }
  }
  const Submit=async(e)=>{
    e.preventDefault()
    if(!images || haserror) return alert("Upload an Image"); 
    try {
      props.loadstate(true)
      const myfiles = Storage.child(images.name);
      await myfiles.put(images);
      alert('File uploaded to Database!:');
      setimages(null)
      setbase(null)
      props.state("")
      props.Fetch()
      props.fun()
      props.loadstate(false)
    } catch (error) {
     sethaserror(error) 
    }
  }
  return (
    <div>
       <section style={{padding:"20px 0px"}} className="contact-section bg-grey padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-7 sm-padding">
                <div className="contact-form">
                  <div className="form-heading">
                    <h3>Here you can upload images for Gallery!</h3>
                    <p>* Upload your image (Type :- png / jpeg / jpg) *</p>
                  </div>
                  <form className="text-center form-horizontal">
                    <div className="form-group colum-row row">
                      <div className="col-sm-12">
                        <input accept='image/*' onChange={newimages} type="file" style={{display: "block",width: "100%",height: "calc(1.5em + 1rem + 2px)",padding: ".375rem .75rem",fontSize: "1rem",fontWeight: 400,lineHeight: 1.5,color: "#495057",backgroundColor: "#fff",backgroundClip: "padding-box",border: "1px solid #ced4da",borderRadius: ".25rem",transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out"}} required />
                      </div>
                    </div>
                    <p style={{textAlign:"center",color:"red"}}>{haserror?"Something went wrong. Try again later!":""}</p>
                    <button id="submit" onClick={Submit} className="default-btn" type="submit">Submit<span /></button>
                    <button style={{marginLeft:"20px"}} className="default-btn" type="reset" onClick={props.fun}>Cancel<span /></button>
                    <div id="form-messages" className="alert" role="alert" />
                  </form>
                </div>
              </div>
              <div className="col-lg-5 sm-padding">
                <div className="contact-content">
                  <div className="contact-item mb-40">
                    <h3 className='text-center'>See your Image here</h3>
                    <div className="img-thumbnail" style={{textAlign:"center"}}>
                        <img src={base?base:"assets/Noimage.jpg"} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}
export default UploadImage