import React from 'react'

const WorkingProcess = () => {
  return (
    <div>
        <section className="working-process bd-bottom padding">
    <div className="container">
      <div className="work-wrap row">
        <div className="col-md-4 sm-padding">
          <div className="work-content bd-right">
            <h2>1</h2>
            <div className="section-heading">
              <h3>Our History</h3>
              <p>SAA , Ch lakhiram arya jagannath ashram was registered in the year 1986 and is working for the holistic development of the children in Rohtak, Haryana, </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 sm-padding">
          <div className="work-content bd-right">
            <h2>2</h2>
            <div className="section-heading">
              <h3>Our Mission</h3>
              <p>SAA , Ch lakhiram arya jagannath ashram exists to transform and empower lives of the people in need to enable them to achieve maximum potential through care, compassion, and concern.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 sm-padding">
          <div className="work-content">
            <h2>3</h2>
            <div className="section-heading">
              <h3>Vission</h3>
              <p>To achieve sustainable change in the lives of abandoned, Surrendered &amp; Orphan children to meet their basic needs of education, health and equal rights with a life cycle approach of development.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>{/* ./ working-process */}
  <section className="service-section-2 bd-bottom bg-grey padding">
    <div className="container">
      <div className="section-heading mb-40 text-center">
        <span className="sub-heading">What we do?</span>
        <h2>Specialized adoption services <br /> creating perfect families and homes.</h2>
        <p>Specialized Adoption Agency to benefit those couple who do not have a child or want to adopt a child to be a perfect parent <br /> to give an opportunity to a child to have a perfect home. </p>
      </div>{/* /.section-heading */}
      <div className="service-wrap row">
        <div className="col-lg-3 col-md-6 sm-padding">
          <div className="service-item bg-yellow text-center">
            <div className="service-content">
              <i className="cht cht-blood-donation-1" />
              <a href="#">
                <h3>Give Donation</h3>
              </a>
              <p>Support our adoption services by donating. Your generosity helps us connect children with loving families, providing them with the opportunity to grow up in a safe and nurturing environment.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding">
          <div className="service-item bg-purple text-center">
            <div className="service-content">
              <i className="cht cht-laurel" />
              <a href="#">
                <h3>Quick Fundraiser</h3>
              </a>
              <p>Host a quick fundraiser to support our adoption efforts. The funds you raise will help us expand our reach, ensuring more children find the perfect home and family they deserve.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding">
          <div className="service-item bg-green text-center">
            <div className="service-content">
              <i className="cht cht-donation-11" />
              <a href="#">
                <h3>Send GiftBox</h3>
              </a>
              <p>Send a gift box to a child waiting for adoption. These boxes include comforting items and essentials that bring joy and hope to children as they await their forever families.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 sm-padding">
          <div className="service-item bg-pink-2 text-center">
            <div className="service-content">
              <i className="cht cht-donor" />
              <a href="volunteer.html">
                <h3>Become Volunteer</h3>
              </a>
              <p>Join us as a volunteer and help make a difference in the lives of children and adoptive families. Your time and dedication can help children find loving homes and supportive parents.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>{/* ./ service-section */}
    </div>
  )
}

export default WorkingProcess