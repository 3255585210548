import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
const AdminHeader = () => {
    const[toogle,settoogle]=useState(false)
    const navigate=useNavigate()
    const checkuser=()=>{
        const user=JSON.parse(localStorage.getItem("User"))
        if(!user) return navigate("/")
    }
    useEffect(()=>{
      checkuser()
    },[])
    function Log()
    {
        localStorage.clear()
        window.history.replaceState(null,null,"/Login")
        navigate("/",{replace:true})
    }
  return (
    <div>
        <header className="header header-one">
                    <div className="primary-header-one primary-header">
                        <div className="container">
                            <div className="primary-header-inner">
                                <div className="header-logo">
                                    <Link to={'/'}>
                                        <img src="../assets/img/logo-main.png" alt="Logo" />
                                    </Link>
                                </div>
                                <div style={toogle?{display:"block"}:{display:"none"}} className="header-menu-wrap">
                                    <ul className="dl-menu">
                                        <li><Link to={'/UploadGallery'}>Gallery</Link></li>
                                        <li><Link to={'/DisplayContact'}>Contacts</Link></li>
                                        {/* <li><Link to={'/'}>Settings</Link></li> */}
                                    </ul>
                                </div>
                                <div className="header-right">
                                    <a onClick={Log} style={{cursor:"pointer"}} className="header-btn">Logout</a>
                                    <div onClick={()=>settoogle(!toogle)} className="mobile-menu-icon">
                                        <div className="burger-menu">
                                            <div className="line-menu line-half first-line" />
                                            <div className="line-menu" />
                                            <div className="line-menu line-half last-line" />
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
      </div>
  )
}
export default AdminHeader