import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
const HomeSlider = () => {
    const navigate=useNavigate()
    const settings = {

        fade: true, // For smooth transitions
        cssEase: 'linear',
        arrows: false,
    };
  return (
    <div>
         <Slider {...settings} className="dl-slider">
                    <div className="single-slide">
                        <div className="bg-img kenburns-top-right" style={{ backgroundImage: 'url(../assets/img/MainBanner.jpg)' }} />
                        <div className="overlay" />
                        <div className="slider-content-wrap d-flex align-items-center text-left">
                            <div className="container">
                                <div className="slider-content">
                                    <div className="dl-caption medium">
                                        <div className="inner-layer">
                                            <div data-animation="fade-in-bottom" data-delay="1s">Help the people in need!</div>
                                        </div>
                                    </div>
                                    <div className="dl-caption big">
                                        <div className="inner-layer">
                                            <div data-animation="fade-in-bottom" data-delay="1.5s">TOGETHER WE CAN<br /> IMPROVE THEIR LIVES</div>
                                        </div>
                                    </div>
                                    <div className="dl-caption big">
                                        <div className="inner-layer">
                                            <div />
                                        </div>
                                    </div>
                                    <div className="dl-caption small">
                                        <div className="inner-layer">
                                            <div>Making a  difference in our community.</div>
                                        </div>
                                    </div>
                                    <div className="dl-btn-group">
                                        <div className="inner-layer">
                                            <a className="default-btn" onClick={()=>navigate("/Contact")}>Make a Donation<span /><i className="arrow_right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Slider>
    </div>
  )
}

export default HomeSlider