import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/auth';

// Initialize Firebase with your Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_YV-S5GXVqTQRnEyG8gi-Q5vipX6wuMA",
  authDomain: "lakhiram-d0844.firebaseapp.com",
  projectId: "lakhiram-d0844",
  storageBucket: "lakhiram-d0844.appspot.com",
  messagingSenderId: "654905895694",
  appId: "1:654905895694:web:0980b6c541d24ba4b4455f"
};
const fire=firebase.initializeApp(firebaseConfig);
export default fire.storage().ref()
export const database= fire.database().ref()
export const Auth=fire.auth()