import React, { useState } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { database } from '../Firebase'
import { useNavigate } from 'react-router-dom'
import ScrollToTop from '../Components/ScrollToTop'
const Contact = () => {
  const[obj,setobj]=useState({})
  const[haserror,sethaserror]=useState(null)
  const navigate=useNavigate()
  const d=new Date()
  const date=`${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
  const set=(event)=>{
    setobj({...obj,[event.target.name]:event.target.value.toLowerCase(),"Date":date})
  }
  const NameChange=(event)=>{
    const name = event.target.value.replace(/[^a-zA-Z\s]/g, '');
    setobj({...obj,"Name":name});
  }
  const PhoneNumberChange=(event)=>{
      const phone = event.target.value.trim().replace(/[^\d-]/g, '');
      setobj({...obj,"Phone":phone});
  }
  function EmailChange(email){
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email)
  }
  const submit=(e)=>{
    e.preventDefault()
    if(!obj.Name || !obj.Phone || !obj.Email || !obj.Address || !obj.Message)
    {
      return alert("Field is Empty")
    }
    const response= EmailChange(obj.Email)
    if(!response)
    {
      sethaserror(true)
      return alert("Please enter a valid email address")
    }
    sethaserror(null)
    database.child("Contacts").push(obj,err=>{
      if(err) return alert("Something went wrong. Try again later")
      alert("Your details are sent to the Lakhiram trust")
      setTimeout(()=>{
        navigate("/")
      },2500)
    })
    setobj({})
  }
  return (
    <div>
      <div>
        <Header/>
        <div className="map-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3492.7572958600263!2d76.58028027551084!3d28.90555947551673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDU0JzIwLjAiTiA3NsKwMzQnNTguMyJF!5e0!3m2!1sen!2sin!4v1724745655445!5m2!1sen!2sin" width="100%" height={400} frameBorder={0} style={{ border: 0 }} allowFullScreen />
        </div>{/* /#google-map */}
        <section className="contact-section bg-grey padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-7 sm-padding">
                <div className="contact-form">
                  <div className="form-heading">
                    <h3>Get in Touch With Us</h3>
                    <p>Your email address will not be published. Required fields are marked *</p>
                  </div>
                  <form className="form-horizontal">
                    <div className="form-group colum-row row">
                      <div className="col-sm-6">
                        <input type="text" onChange={NameChange} value={obj.Name?obj.Name:""} name="Name" className="form-control" placeholder="Enter your Name" required />
                      </div>
                      <div className="col-sm-6">
                        <input type="tel" onChange={PhoneNumberChange} value={obj.Phone?obj.Phone:""} name="Phone" className="form-control" placeholder="Enter your Phone Number" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        <input type="email" onChange={set} value={obj.Email?obj.Email:""} name="Email" className="form-control" placeholder="Enter your Email" required />
                        {haserror && <p style={{color: 'red'}}>Please enter a valid email address</p>}
                      </div>
                      <div className="col-sm-6">
                        <input type="text" onChange={set} value={obj.Address?obj.Address:""} name="Address" className="form-control" placeholder="Enter your Address" required />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-12">
                        <textarea name="Message" onChange={set} value={obj.Message?obj.Message:""} cols={30} rows={5} className="form-control address" placeholder="Enter the Message" required />
                      </div>
                    </div>
                    <button onClick={submit} className="default-btn" type="submit">Send Message<span /></button>
                    <div id="form-messages" className="alert" role="alert" />
                  </form>
                </div>
              </div>
              <div className="col-lg-5 sm-padding">
                <div className="contact-content">
                  <div className="contact-item mb-40">
                    <h3>Get In Touch.</h3>
                    <ul className="contact-details">
                      <li><i className="fas fa-map-marker-alt" />SAA , Ch lakhiram arya jagannath ashram Dayanand math, Gohana&nbsp;road&nbsp;rohtak</li>
                      <li><i className="fas fa-phone" />(+123) 456 789 101</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
        <div id="scrollup">
          <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
        </div>
      </div>
      <ScrollToTop/>
    </div>
  )
}

export default Contact