import React, { useEffect, useState } from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Home from './Pages/Home'
import WhatWeDo from './Pages/WhatWeDo'
import WhoWeAre from './Pages/WhoWeAre'
import Volunteer from './Pages/Volunteer'
import Sponsership from './Pages/Sponsership'
import Gallery from './Pages/Gallery'
import Contact from './Pages/Contact'
import Adoption from './Pages/Adoption'
import "./assets/css/fontawesome.min.css"
import "./assets/css/themify-icons.css"
import "./assets/css/elegant-line-icons.css"
import "./assets/css/animate.min.css"
import "./assets/css/charitian-icons.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/slick.min.css"
import "./assets/css/slider.css"
import "./assets/css/venobox/venobox.css"
import "./assets/css/main.css"
import Login from './Pages/Login'
import UploadGallery from './AdminPages/UploadGallery'
import DisplayContact from './AdminPages/DisplayContact'
import Storage from './Firebase'
import Context from './Pages/Context'
const App = () => {
  const[images,setimages]=useState("")
  const[loader,setloader]=useState(false)
  const[haserror,sethaserror]=useState(null)
  const[nextPageToken,setnextPageToken]=useState(null)
  const[isLoading,setisLoading]=useState(false)
  const FetchImages=async(pageToken = null)=>{
    try{
      let listOptions = { maxResults: 9 };
      if (pageToken) {
        listOptions.pageToken = pageToken; // Pass pageToken for pagination
      }
      const result=await Storage.list(listOptions)
      if(result.items.length>0)
      { 
        let data=[]
        for (let item of result.items) {
          const downloadURL = await item.getDownloadURL()
          data.push(downloadURL);  // Collect URLs
        }
        setimages(images=>[...images,...data])
        if (result.nextPageToken) {
          setnextPageToken(result.nextPageToken); // Store nextPageToken for future use
        } else {
          setnextPageToken(null);  // No more pages
        }   
      }
    } catch (error) {
      sethaserror(error)
    }
  }
  useEffect(()=>{
    setloader(true)
    FetchImages()
    setTimeout(()=>setloader(false),3000)
  },[])
  const LoadMore=async()=>{
    try {
    if(!nextPageToken) return alert("No More images found");
    setisLoading(true)
    await FetchImages(nextPageToken)
    setisLoading(false)
    } catch (error) {
      sethaserror(error)
    }
  }
  return (
    <div>
      <Context.Provider value={{"Fetch":images,"Loader":loader,"Error":haserror,"Loading":isLoading,"LoadMore":LoadMore}}>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/WhatWeDo' element={<WhatWeDo/>} />
        <Route path='/WhoWeAre' element={<WhoWeAre/>} />
        <Route path='/Volunteer' element={<Volunteer/>} />
        <Route path='/Sponsership' element={<Sponsership/>} />
        <Route path='/Gallary' element={<Gallery/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/Adoption' element={<Adoption/>} />
        <Route path='/Login' element={<Login/>} />
        <Route path='/UploadGallery' element={<UploadGallery/>} />
        <Route path='/DisplayContact' element={<DisplayContact/>} />
      </Routes>
      </BrowserRouter>
      </Context.Provider>
    </div>
 )
}

export default App