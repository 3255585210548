import React from 'react'
import { useNavigate } from 'react-router-dom'

const CounterSection = () => {
  const navigate=useNavigate()
  return (
    <div>
        <section className="counter-section padding">
    <div className="container">
      <div className="counter-wrap row">
        <div className="col-lg-3 col-sm-6 sm-padding wow fadeInUp" data-wow-delay="200ms">
          <div className="counter-item">
            <div className="counter-content">
              <i className="cht cht-blood-donation-1 color-red" />
              <h3><span className="odometer" data-count="2,359">00</span></h3>
              <h4>Children Adopted</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 sm-padding wow fadeInUp" data-wow-delay="300ms">
          <div className="counter-item">
            <div className="counter-content">
              <i className="cht cht-donation-14 color-green" />
              <h3><span className="odometer" data-count="1,175">00</span></h3>
              <h4>Families Supported</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 sm-padding wow fadeInUp" data-wow-delay="400ms">
          <div className="counter-item">
            <div className="counter-content">
              <i className="cht cht-donor color-blue" />
              <h3><span className="odometer" data-count="1,519">00</span></h3>
              <h4>Successful Adoptions                              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 sm-padding wow fadeInUp" data-wow-delay="500ms">
          <div className="counter-item">
            <div className="counter-content">
              <i className="cht cht-charity-5 color-yellow" />
              <h3><span className="odometer" data-count={2854}>00</span></h3>
              <h4>Volunteers Engaged</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>{/* ./ counter-section-2 */}
  <section className="cta-section-3 bd-bottom padding">
    <div className="container">
      <div className="cta-wrap text-center">
        <div className="section-heading mb-40 text-center">
          <span className="sub-heading">How We Help</span>
          <h2>We Help couple who do not have a child <br /> want to adopt a child </h2>
          <p>Specialized Adoption Agency to benefit those couple who do not have a child or want to adopt a child to be a perfect parent <br /> to give an opportunity to a child to have a perfect home.</p>
        </div>{/* /.section-heading */}
        <a className="default-btn" onClick={()=>navigate("/Volunteer")}>Become a volunteer<span /></a>
      </div>
    </div>
  </section>{/* ./ cta-section-3 */}
    </div>
  )
}

export default CounterSection