import React from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Banner from '../Components/Banner'
import SponserBlog from '../Components/SponserBlog'
import ScrollToTop from '../Components/ScrollToTop'

const Sponsership = () => {
  return (
    <div>
      <div>
        <Header/>
        <Banner Name={"Sponser a Child"} />
        <SponserBlog/>
        <Footer/>
        <div id="scrollup">
          <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
        </div>
      </div>
      <ScrollToTop/>
    </div>
  )
}

export default Sponsership