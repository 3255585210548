import React from 'react'

const AdoptSect = () => {
  return (
    <div>
           <section className="blog-section bg-white padding">
          <div className="container">
            <div className="blog-wrap row">
              <div className="col-lg-12 padding-15">
                <div className="blog-single-wrap">
                  <div className="blog-single-content">
                    <h2 style={{ textAlign: 'center' }}>Adoption</h2>
                    <p>Welcome to our Specialised Adoption Agency in rohtak!</p>
                    <p>We are proud to be the sole Adoption Agency in rohtak and the surrounding areas. Our organization has been granted approval by the State Government of Haryana, specifically by the Women &amp; Child Development Department. This recognition has designated us as a Specialized Adoption Agency, affirming our commitment to the welfare of children.</p>
                    <p>As an affiliated agency with CARA (Central Adoption Resource Authority), we are authorized to facilitate both In-Country and inter-country adoptions in accordance with the prevailing provisions. We adhere to the guidelines and regulations set forth by CARA to ensure a transparent and ethical adoption process.</p>
                    <blockquote>
                      <div className="dots" />
                      <ul className="mainsecondul">
                        <li>1. Online Registration: Visit the website <a href="https://cara.wcd.gov.in/">cara.wcd.gov.in</a> to register as a prospective adoptive parent. Ensure that you review the instructions thoroughly before proceeding.</li>
                        <li>2. Acknowledgement Letter: Upon successful registration, you will receive an acknowledgement letter online. This letter will contain your registration details and credentials.</li>
                        <li>3. Document Submission: Within 30 days of registration, please upload the following documents:</li>
                      </ul>
                      <ul className="mainul">
                        <li>PAN CARDs &amp; AADHAR CARDs of both Prospective Adoptive Parents.</li>
                        <li>PHOTOGRAPH of the prospective adoptive parents. If you already have a child, please provide a photograph of the parents with the child.</li>
                        <li>PROOF OF ADDRESS indicating residence in India exceeding 365 days - Aadhar Card/Voter ID/Electric Bill/Telephone Bill</li>
                        <li>MEDICAL CERTIFICATE issued by a registered medical practitioner, certifying that the Prospective Adoptive Parents do not suffer from any chronic, contagious, or fatal disease and confirming their fitness to adopt a child, should not be older than 3 months.</li>
                        <li>AGE PROOF: Both of you need to provide an age proof, which can be either a Birth Certificate or Board Class Mark Sheet. Affidavit or any other document will not be considered as proof of age for the PAPs</li>
                        <li>MARRIAGE CERTIFICATE (affidavit is not applicable).</li>
                        <li>INCOME CERTIFICATE from the employer for the last 6 months OR Income Tax Return (ITR) for the last 3 years.</li>
                        <li>TWO LETTERS OF RECOMMENDATION from individuals who are familiar with the family, written on a plan paper, are required. These recommendations must not come from immediate relatives of either spouse. Additionally, the individuals providing the recommendations should provide a signed copy of their ID proof.</li>
                        <li>CONSENT LETTER of the biological or  adopted child or children and if they are above 5 years of age.</li>
                        <li>MEDICAL CERTIFICATE Medical certificate of the older child of the PAPs, if applicable.</li>
                        <li>BIRTH CERTIFICATE of the older child of the Prospective Adoptive Parents, if applicable</li></ul>
                    </blockquote>
                    <p>Please note that your eligibility for adoption will be assessed by our adoption agency. It is essential to provide accurate and complete information throughout the process. Incomplete or incorrect information may lead to the invalidation of your application.</p>
                  </div>
                </div>{/*/.blog-single*/}
              </div>{/*/.col-lg-8*/}
            </div>{/*/.blog-wrap*/}
          </div>
        </section>{/*/.blog-section*/}
    </div>
  )
}

export default AdoptSect