import React,{useState} from 'react'
import { Auth } from '../Firebase'
import { useNavigate } from 'react-router-dom'
const Loginform = () => {
  const[obj,setobj]=useState({})
  const navigate=useNavigate()
  const[haserror,sethaserror]=useState(null)
  const set=(event)=>{
    setobj({...obj,[event.target.name]:event.target.value.trim()})
  }
  const EmailChange=(email)=>{
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email)
  }
  const submit=async(e)=>{
    e.preventDefault()
   try {
    if(!obj.Email || !obj.Password)
    {
      sethaserror("Field is Empty")
      return alert("Field is Empty")
    }
    const response=EmailChange(obj.Email)
    if(!response)
    {
      sethaserror("Invalid Email")
      return alert("Invalid Email")
    }
    const userCredential = await Auth.signInWithEmailAndPassword(obj.Email,obj.Password)
    localStorage.setItem("User",JSON.stringify(userCredential.user.uid))
    navigate("/UploadGallery")
   } catch (error) {
      console.log(error);
      sethaserror("Invalid Credentials")
      return alert("Invalid Credentials")
   }
  }
  return (
    <div>
      <section className="contact-section bg-grey padding">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-7 sm-padding">
                <div className="contact-form">
                  <div className="form-heading">
                    <h3>Login Yourself</h3>
                    <p>*Fill the fields provided to enter the Admin Panel* </p>
                  </div>
                  <form style={{textAlign:"center"}} className="form-horizontal">
                    <div className="form-group colum-row row">
                      <div className="col-sm-12">
                        <input type="email" onChange={set} name="Email" className="form-control" placeholder="Enter the Email" required />
                      </div>
                      <div className="col-sm-12">
                        <input type="password" onChange={set} name="Password" className="form-control" placeholder="Enter the Password" required />
                      </div>
                    </div>
                    <p style={{color:"red",fontSize:"15px"}}>{haserror?haserror:""}</p>
                    <button onClick={submit} className="default-btn" type="submit">Login<span /></button>
                    <div id="form-messages" className="alert" role="alert" />
                  </form>
                </div>
              </div>
              <div className="col-lg-5 sm-padding">
                <div className="contact-content">
                  <div className="contact-item mb-40">
                    <h3>Contact Details</h3>
                    <ul className="contact-details">
                      <li><i className="fas fa-map-marker-alt" />SAA , Ch lakhiram arya jagannath ashram Dayanand math, Gohana&nbsp;road&nbsp;rohtak</li>
                      <li><i className="fas fa-phone" />(+123) 456 789 101</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Loginform