import React from 'react'
// ../img/Volunteer.jpg
const Banner = (props) => {
  return (
    <div>
       <section   className="page-header padding">
    <div className="overlay" />
    <div className="container">
      <div className="page-content text-center">
        <h2>{props.Name}</h2>
      </div>
    </div>
  </section>{/* /.page-header */}
    </div>
  )
}

export default Banner