import React from 'react'
import AdminHeader from '../AdminComponents/AdminHeader'
import AdminFooter from '../AdminComponents/AdminFooter'
import Banner from '../Components/Banner'
import ContactDetails from '../AdminComponents/ContactDetails'
import ScrollToTop from '../Components/ScrollToTop'

const DisplayContact = () => {
  return (
    <div>
      <AdminHeader/>
      <Banner Name="See who want to contact you!"/>
      <ContactDetails/>
      <AdminFooter/>
      <ScrollToTop/>
    </div>
  )
}

export default DisplayContact
