import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
        <section className="widget-section padding">
                    <div className="widget-bg" />
                    <div className="container">
                        <div className="widget-wrap row">
                            <div className="col-lg-3 col-md-6 sm-padding sm-padding">
                                <div className="widget-box">
                                    <img src="../assets/img/logo-main.png" alt="img" />
                                    <p> Ch. Lakhiram Arya Jagannath Ashram: dedicated to nurturing and placing children in loving adoptive homes.</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 sm-padding sm-padding">
                                <div className="widget-box">
                                    <div className="widget-title">
                                        <h3>Pages</h3>
                                    </div>
                                    <ul className="widget-item">
                                        <li><Link to={'/'} >Home</Link></li>
                                        {/* <li><Link to={'/WhoWeAre'} >About</Link></li> */}
                                        <li><Link to={'/Volunteer'} >Volunteer</Link></li>
                                        <li><Link to={'/Gallary'} >Gallery</Link></li>
                                        <li><Link to={'/Contact'} >Contact</Link></li>
                                        <li><Link to={'/Login'} >Login</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 sm-padding sm-padding">
                                <div className="widget-box ">
                                    <div className="widget-title">
                                        <h3>About Us</h3>
                                    </div>
                                    <ul className="widget-item">
                                        <li><Link to={'/WhoWeAre'}>Who we are</Link></li>
                                        <li><Link to={'/WhatWeDo'}>What we do</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 sm-padding sm-padding">
                                <div className="widget-box">
                                    <div className="widget-title">
                                        <h3>Headquarters</h3>
                                    </div>
                                    <ul className="widget-contact">
                                        <li>
                                            <i className="fas fa-map-marker-alt" />
                                            <span>SAA , Ch lakhiram arya jagannath ashram
                                                Dayanand math, Gohana&nbsp;road&nbsp;rohtak</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-phone" />
                                            <span>(+123) 456 789 101</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* ./ widget-section */}
                <footer className="footer-section text-center">
                    <div className="container">
                        <p>© Copyright 2024 Code Pulse IT Services- All Rights Reserved</p>
                    </div>
                </footer>{/* /.footer-section */}
    </div>
  )
}

export default Footer