import React from 'react'
import Header from '../Components/Header'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import VolunteerBlog from '../Components/VolunteerBlog'
import ScrollToTop from '../Components/ScrollToTop'

const Volunteer = () => {
  return (
    <div>
      <div>
       <Header/>
       <Banner Name={"Volunteer"} />
        <VolunteerBlog/>
        <Footer/>
        <div id="scrollup">
          <button id="scroll-top" className="scroll-to-top"><i className="ti-angle-up" /></button>
        </div>
      </div>
      <ScrollToTop/>
    </div>
  )
}

export default Volunteer