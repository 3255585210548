import React from 'react'
import { useNavigate } from 'react-router-dom'
const SponserBlog = () => {
  const navigate=useNavigate()
  return (
    <div>
        <section className="blog-section padding">
          <div className="container">
            <div className="blog-wrap row">
              <div className="col-lg-12 sm-padding">
                <div className="cause-wrap">
                  <div className="section-heading mb-40 text-center">
                    <h2> Sponsor a Child at SAA , Ch lakhiram arya jagannath ashram</h2>
                    <h4>Specialized Adoption Agency </h4>
                  </div>{/* /.section-heading */}
                  <div className="cause-content">
                    <p className="desc">Your sponsorship extends beyond mere financial support; it transcends the realm of dollars and cents. Instead, it signifies so much more – it embodies hope, care, and the promise of a brighter future. When you choose to sponsor a child, you are not merely providing funding; you are extending a lifeline of opportunities that they might not otherwise have access to. It's akin to planting the seeds of possibility in their lives, nurturing their potential so that it can blossom and flourish. By becoming a sponsor, you become a catalyst for positive change, igniting a spark within these children that empowers them to reach for the stars, to dream big, and to pursue a path towards a better tomorrow. <br /> Your sponsorship is more than an investment in their education or well-being; it is an investment in their dreams, their aspirations, and their ability to make a lasting impact on their communities and the world. Together, we can create a chain reaction of transformative change, one child at a time.</p>
                    <blockquote>
                      <div className="dots" />
                      <p>“Empower a Future, One Child at a Time.”</p>
                      <span className="quoter">- Unknown</span>
                    </blockquote>
                    <h2 style={{ textAlign: 'center' }}>Sponsorship Opportunities</h2>
                    <h3>1. Education Sponsorship (5000/- per month):</h3>
                    <p>Your contribution towards a child's education is a direct investment in their future. It ensures access to quality education, covering school fees, books, uniforms, and necessary educational materials. By sponsoring education, you:</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Empowerment Through Knowledge:</span>  Enable a child to break the cycle of poverty by gaining education, expanding their opportunities, and developing critical skills for a brighter future.</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Community Transformation</span> Education doesn't just benefit the child; it elevates the community by fostering educated individuals who can contribute positively to society.</p>
                    <h3>2. Medical Expenses Support (5000/-):</h3>
                    <p>Your support for medical expenses provides crucial healthcare access to children facing health challenges. This sponsorship:</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Ensures Vital Care: </span> Your contribution aids in covering medical treatments, doctor visits, medications, and necessary medical procedures for a child in need.</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Health and Well-being:</span>Sponsorship — Miracle Charitable Society.</p>
                    <h3>3. Meal Sponsorship (5000/- per meal):</h3>
                    <p>Sponsoring meals for children residing at Miracle Charitable Society ensures that they receive nutritious and regular meals. Your sponsorship:</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Fights Hunger::</span>Provides essential nourishment, combating hunger and malnutrition among underprivileged children.</p>
                    <p> <span style={{ textDecoration: 'underline' }}>Supports Growth and Development:</span>Proper nutrition is fundamental for a child's growth, development, and overall health, enabling them to focus on education and other activities.</p>
                  </div>
                  <div className="col-lg-12 sm-padding">
                    <div className="contact-form">
                      <div className="form-heading">
                        <h3 style={{ marginBottom: 40 }}>Start Changing Lives Today: Sponsor a Child and Make a Lasting Impact</h3>
                      </div>
                      <form className="form-horizontal text-center">
                        {/* <div className="form-group colum-row row">
                          <div className="col-sm-6">
                            <input type="text" id="contact-firstname" name="contact-firstname" className="form-control custom" placeholder="First Name" required />
                          </div>
                          <div className="col-sm-6">
                            <input type="text" id="contact-lastname" name="contact-lastname" className="form-control custom" placeholder="Last Name" required />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <input type="email" id="contact-email" name="contact-email" className="form-control custom" placeholder="Email" required />
                          </div>
                          <div className="col-sm-6">
                            <input type="text" id="contact-phone" name="contact-phone" className="form-control custom" placeholder="Phone Number" required />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12">
                            <textarea id="contact-message" name="contact-message" cols={30} rows={5} className="form-control custom address" placeholder="Message" required defaultValue={""} />
                          </div>
                        </div> */}
                        <button onClick={()=>navigate("/Contact")} className="default-btn" type="submit">Contact Us<span /></button>
                        <div id="form-messages" className="alert" role="alert" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>{/* col-lg-8 */}
            </div>{/* /.blog-wrap */}
          </div>
        </section>{/* /.blog-section */}
    </div>
  )
}

export default SponserBlog