import React from 'react'

const HomeTeam = () => {
  return (
    <div>
        {/* ./ team-secction */}
        <section className="cta-section bd-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 sm-padding">
                                <div className="cta-content">
                                    <div className="section-heading text-left">
                                        <span className="sub-heading">Join With Us</span>
                                        <h2>We Have Helped Over 120,000 <br /> Children Find Loving Homes Around the World.</h2>
                                        <p>Supporting adoption is more than just a charitable act—it's about giving children the chance to thrive in a safe, loving environment. Your involvement helps us ensure that every child receives the care and support they deserve.</p>
                                    </div>{/* /.section-heading */}
                                </div>
                            </div>
                            <div className="col-md-6 sm-padding">
                                <form className="form-horizontal appointment-form">
                                    <div className="section-heading">
                                        <span>Join Us Now</span>
                                        <h3>Become A Volunteer</h3>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6">
                                            <input type="text" id="volunteer-form-name" name="volunteer-name" className="form-control" placeholder="Your Name" required />
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="email" id="volunteer-email" name="volunteer-email" className="form-control" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-6">
                                            <input type="text" id="volunteer-phone" name="volunteer-phone" className="form-control" placeholder="Phone No" required />
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="text" id="volunteer-address" name="volunteer-address" className="form-control" placeholder="Your address" required />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-12">
                                            <textarea id="volunteer-message" name="volunteer-message" cols={30} rows={5} className="form-control message" placeholder="Message" required defaultValue={""} />
                                        </div>
                                    </div>
                                    <button id="volunteer-submit" className="default-btn" type="submit">Become A Volunteer <span /></button>
                                    <div id="volunteer-form-messages" className="alert" role="alert" />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>{/* ./ cta-section */}
                <section className="subscribe-section bd-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="subscribe-wrap">
                                    <div className="sub-bg" />
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="section-heading">
                                                <h2>Become a volunteer</h2>
                                                <p>Support Childrens at <br />our Specialised Adoption Agency</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="subscribe-form-wrap">
                                                <form action="#" className="subscribe-form">
                                                    <input type="email" name="email" className="form-input" placeholder="Enter Your Email Address..." required />
                                                    <input type="hidden" name="action" defaultValue="mailchimpsubscribe" />
                                                    <button className="submit-btn">Become a volunteer</button>
                                                    <div className="clearfix" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>{/* ./ subscribe-section */}
    </div>
  )
}

export default HomeTeam