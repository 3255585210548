import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const Header = () => {
    const[toogle,settoogle]=useState(false)
    const [FirstToogle, setFirstToogle] = useState(false);
    const [SecondToogle, setSecondToogle] = useState(false);
    const [ThirdToogle, setThirdToogle] = useState(false);
  return (
    <div>
        <header className="header header-one">
                    <div className="primary-header-one primary-header">
                        <div className="container">
                            <div className="primary-header-inner">
                                <div className="header-logo">
                                    <Link to={'/'}>
                                        <img src="../assets/img/logo-main.png" alt="Logo" />
                                    </Link>
                                </div>{/* /.header-logo */}
                                <div style={toogle?{display:"block"}:{display:"none"}} className="header-menu-wrap">
                                    <ul className="dl-menu">
                                        <li><Link to={'/'} >Home</Link></li>
                                        <li onClick={()=>setFirstToogle(!FirstToogle)}><Link>About</Link>
                                            <ul style={FirstToogle?{display:"block"}:{display:""}} >
                                                <li><Link to={'/WhoWeAre'} >Who We Are</Link></li>
                                                <li><Link to={'/WhatWeDo'} >What We Do</Link></li>
                                            </ul>
                                            <span className="dropdown-plus"></span>
                                        </li>
                                        <li onClick={()=>setSecondToogle(!SecondToogle)}><Link>Projects</Link>
                                            <ul style={SecondToogle?{display:"block"}:{display:""}} >
                                                <li><Link  to={'/Adoption'} >Specialised Adoption Agency</Link></li>
                                            </ul>
                                            <span className="dropdown-plus"></span>
                                        </li>
                                        <li><Link to={'/Gallary'} >Gallery</Link></li>
                                        <li onClick={()=>setThirdToogle(!ThirdToogle)}><Link>Pages</Link>
                                            <ul style={ThirdToogle?{display:"block"}:{display:""}}>
                                            <li><Link to={'/Sponsership'}>Sponsership</Link></li>
                                            <li><Link to={'/Volunteer'}>Volunteer</Link></li>
                                            <li><Link to={"/Login"}>Login</Link></li>
                                            </ul>
                                            <span className="dropdown-plus"></span>
                                        </li>
                                        <li><Link to={'/Contact'}>Contact Us</Link></li>
                                    </ul>
                                </div>{/* /.header-menu-wrap */}
                                <div className="header-right">
                                    <Link className="header-btn" to={'/Contact'} >Donate Now<span /></Link>
                                    {/* Burger menu */}
                                    <div onClick={()=>settoogle(!toogle)} className="mobile-menu-icon">
                                        <div className="burger-menu">
                                            <div className="line-menu line-half first-line" />
                                            <div className="line-menu" />
                                            <div className="line-menu line-half last-line" />
                                        </div>
                                    </div>
                                </div>{/* /.header-right */}
                            </div>{/* /.primary-header-one-inner */}
                        </div>
                    </div>{/* /.primary-header-one */}
                </header>{/* /.header-one */}
      </div>
  )
}
export default Header