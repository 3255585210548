import React, { useEffect, useState } from 'react'
import AdminHeader from '../AdminComponents/AdminHeader'
import AdminFooter from '../AdminComponents/AdminFooter'
import Banner from '../Components/Banner'
import UploadImage from '../AdminComponents/UploadImage'
import AdminGalleryImages from '../AdminComponents/AdminGalleryImages'
import Storage from '../Firebase'
import "../Mystyle.css"
import ScrollToTop from '../Components/ScrollToTop'
const UploadGallery = () => {
  const[isData,setisData]=useState(false)
  const[images,setimages]=useState("")
  const[loader,setloader]=useState(false)
  const[haserror,sethaserror]=useState(null)
  const[nextPageToken,setnextPageToken]=useState(null)
  const[isLoading,setisLoading]=useState(false)
  const[length,setlength]=useState(0)
  const FetchImages=async(pageToken = null)=>{
    try{
      let listOptions = { maxResults: 9 };
      if (pageToken) {
        listOptions.pageToken = pageToken; // Pass pageToken for pagination
      }
      const result=await Storage.list(listOptions)
      if(result.items.length>0)
      {
        setisData(true) 
        let data=[]
        for (let item of result.items) {
          const downloadURL = await item.getDownloadURL()
          const path=item.fullPath
          data.push({downloadURL,path});  // Collect URLs
        }
        setimages(images=>[...images,...data])
        if (result.nextPageToken) {
          setnextPageToken(result.nextPageToken); // Store nextPageToken for future use
        } else {
          setnextPageToken(null);  // No more pages
        }   
      }
      else
      {
        setisData(false)
      }
    } catch (error) {
      sethaserror(error)
    }
  }
  const CountImages=async()=>{
    try {
      const result= await Storage.listAll()
      setlength(result.items.length); 
    } catch (error) {
      sethaserror(error)
    }
  }
  useEffect(()=>{
    setloader(true)
    FetchImages()
    CountImages()
    setTimeout(()=>setloader(false),2000)
  },[])
  const Cancel=()=>{
    setisData(true)
  }
  const Upload=()=>{
    setisData(false)
  }
  const LoadMore=async()=>{
    try {
    if(!nextPageToken) return alert("No More images found");
    setisLoading(true)
    await FetchImages(nextPageToken)
    setisLoading(false)
    } catch (error) {
      sethaserror(error)
    }
  }
  const Delete=(path)=>{
    setloader(true)
    Storage.child(path).delete().then(err=>{
      if(err) return sethaserror(err);
      alert("Image Deleted Successfully")
      setimages("")
      FetchImages()
      CountImages()
      setloader(false)
    })
  }
  return (
    <div>
      <AdminHeader/>
      <Banner Name="Upload your Images for Gallery!"/>
      {
      loader?<div className="preloader">
        <div className="loader"></div>
      </div>:""
      }
      {
        isData?<AdminGalleryImages delete={Delete} len={length} ourerror={haserror} makeload={isLoading} load={LoadMore} data={images} fun={Upload}/>:<UploadImage loadstate={setloader} state={setimages} Fetch={FetchImages} fun={Cancel}/>
      }
      <AdminFooter/>
      <ScrollToTop/>
    </div>
  )
}

export default UploadGallery
